import React from "react";
import './Privacy.css'; // Import the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to <strong>Mocha Rewards</strong>. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains our data collection and processing practices and your options regarding the use of your personal data. If you have any requests or questions concerning your personal information, please contact us on our contact us page.
      </p>

      <p>
        Please be advised that Mocha Rewards' practices with respect to data collected and used by Mocha Rewards in connection with this website and each other Mocha Rewards LLC. website with links to this policy are governed by this online privacy policy (“Privacy Policy”) as amended from time to time, and not by the privacy policy in effect at the time the data was collected. Please review our Privacy Policy regularly.
      </p>

      <p>
        If you have objections to the Privacy Policy, you should immediately discontinue use of the Site and the Products and Services enabled by the Site.
      </p>

      <h4>Information Collected</h4>
      <p>
        Mocha Rewards collects the information which is provided by you on registration, together with information we learn about you from your use of our service, your visits to our website, and your visits to other sites accessible from our website. We also collect information about the transactions you undertake, including details of payment cards used.
      </p>
      <p>
        We may collect additional information in connection with your participation in any promotional programs offered by Mocha Rewards, and information you provide when giving us feedback or completing profile forms. We also monitor customer traffic patterns and site usage, which enables us to improve the products and services which Mocha Rewards and its Members provide. In addition, Mocha Rewards will access the information retrieved through your use of your Mocha Rewards Card.
      </p>

      <h4>Use of Your Information and Your Preferences</h4>
      <p>
        We will use your information to provide and personalize Mocha Rewards services, to help us improve our service to you, and to make our communications more relevant.
      </p>
      <p>
        We will also use your contact details to regularly communicate with you. We may use your information to send you offers and information from Mocha Rewards, as well as other carefully selected companies which we think may be of interest to you.
      </p>
      <p>
        Once you have registered to use www.mocharewards.com, you will be provided with access to a “Contact Preferences” page that will allow you to tailor our commercial communications to your preferences. To change your contact preferences simply click “My Account” in the top frame and click “Your Contact Preferences”. If you do not want to receive commercial communications from us, please select your choices by using the boxes available on that page.
      </p>
      <p>
        Opting into our text messaging program, Mocha Rewards Mobile Savings, can only be done by texting a keyword to the short code, this is not managed under the account settings. This short code campaign does not send you any third party marketing messages.
      </p>
      <p>
        Mocha Rewards would like to hear your views to help us improve our service. From time to time, we may contact you to solicit your opinions. Again, if you do not wish to be contacted for this purpose, please make your choice on the “Contact Preferences” page.
      </p>
      <p>
        Please note that there may be instances where it may be necessary for us to communicate with you, regardless of your contact preferences, for administrative or operational reasons relating to our service.
      </p>

      <h4>Disclosures of Your Information</h4>
      <p>
        Your information may be used by Mocha Rewards, LLC., all of its related companies, and all of its Members. We will never pass your personal data to anyone else, except for any successors to our business and the organizations that process data on our behalf. We may also use and disclose information in the aggregate (so that no individual customers are identified) for marketing programs, advertisers, and partners.
      </p>
      <p>
        The above excludes text messaging originator opt-in data and consent; this information will not be shared with any third parties.
      </p>
      <p>
        Mocha Rewards may display targeted advertisements based on personal information. Mocha Rewards does not provide any individual personal information to the advertiser when you interact with or view a targeted ad. Advertisers (including ad serving companies) may, however, assume that people who interact with, view, or click targeted ads meet the targeting criteria—for example, women ages 18-24 from a particular geographic area.
      </p>
      <p>
        We may transfer information about you if Mocha Rewards is acquired by or merged with another company. In this event, Mocha Rewards will notify you before information about you is transferred and becomes subject to a different privacy policy by updating this page.
      </p>

      <h4>Legal Disclaimer</h4>
      <p>
        We reserve the right to disclose your personally identifiable information as required by law and when we believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Mocha Rewards terms of use, or as otherwise required by law.
      </p>

      <h4>Other Web Sites</h4>
      <p>
        Mocha Rewards website may contain links to other websites which are outside Mocha Rewards control and are, therefore, not covered by this Privacy Policy. If you access other sites using the links provided, the operators of these sites may collect information from you which will be used by them in accordance with their privacy policy, which may differ from ours.
      </p>

      <h4>Cookies</h4>
      <p>
        “Cookies” are small pieces of information sent by a web server to a web browser, which enables the server to collect information from the browser. Mocha Rewards uses cookies for a number of purposes, for instance to enable us to simplify the logging on process for registered users, to help ensure the security and authenticity of registered users, to provide the mechanisms for online shopping and to enable traffic monitoring.
      </p>
      <p>
        Mocha Rewards uses both session ID cookies and/or persistent cookies. We use session cookies to make it easier for you to navigate our site. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time, unless you remove the cookie in the manner determined by your particular Internet browser. Disabling the cookie function for your Internet browser will restrict the online services you can use at the Mocha Rewards website.
      </p>

      <h4>Security</h4>
      <p>
        The security of your personal information is very important to us. When you enter sensitive information (such as a credit card number) on our order forms, we encrypt that information using secure socket layer technology (SSL). Once we receive the data it is immediately re-encrypted prior to storage. By default, payment card data is permanently destroyed within 91 days of submission.
      </p>
      <p>
        Mocha Rewards follows generally accepted industry standards to protect the personal information submitted by you, both during transmission and upon Mocha Rewards receipt. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while Mocha Rewards strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
      </p>

      <h4>Changes in this Privacy Statement</h4>
      <p>
        Mocha Rewards reserves the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, we will notify you here.
      </p>
    </div>
  );
};

export default PrivacyPolicy;