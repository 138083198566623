import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Import the CSS file for styling

const App_Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} MochaRewards. All rights reserved. <Link to="/privacy" className="privacy-link">Privacy Policy</Link></p>
    </footer>
  );
};

export default App_Footer;