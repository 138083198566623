
const API_URL = 'https://mocharewards.com/api'; // Replace with your actual API URL

const fetchApi = async (endpoint, method = 'GET', body = null) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    const config = {
        method,
        headers,
        body: body ? JSON.stringify(body) : null,
    };

    try {
        const response = await fetch(`${API_URL}${endpoint}`, config);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('API request error:', error);
        throw error;
    }
};

export const sendEmail = (formData) => {
    return fetchApi('/forwardContactEmail', 'POST', formData);
};

// Add more service functions here as needed
