import logo from './logo.svg';
import './App.css';
import App_Navbar from './components/Navbar';
import App_Hero from './components/hero/Hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import App_Feature from './components/features/Features';
import App_ContactForm from './components/contact/Contact';
import App_Footer from './components/footer/Footer';
import ProductComponent from './components/product/ProductSection';
import 'animate.css';

import { Route, Routes } from 'react-router-dom';
import Privacy from './components/privacy/Privacy';

function App() {
  return (
    <div className="App">
      <App_Navbar/>
      <Routes>
        <Route path="/" element={<>
          <App_Hero/>
          <ProductComponent/>
          <App_Feature/>
          <section id="contact">
            <App_ContactForm/>
          </section>
        </>} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <App_Footer/>
    </div>
  );
}

export default App;
